import React from 'react';
import { Link } from 'gatsby';

type Props = {
  brandLogo: JSX.Element;
  tel: string;
  line: string;
};

const Header: React.FC<Props> = ({ brandLogo, tel, line }) => {
  const [navHidden, setNavHidden] = React.useState(true);

  const toggleNavHidden = React.useCallback(() => {
    setNavHidden(!navHidden);
  }, []);
  const hideNavHidden = React.useCallback(e => {
    setNavHidden(true);
  }, []);

  const navButton = React.useRef<HTMLButtonElement>(null);

  return (
    <header className={`${navHidden ? '' : 'nav-opened'}`}>
      <a href="#top" className="brand logo">
        {brandLogo}
      </a>

      <button className="line" onClick={() => (location.href = line)}>
        <i className="icon-line"></i>
      </button>
      <button className="call" onClick={() => (location.href = `tel: ${tel}`)}>
        <i className="icon-phone"></i>
      </button>

      <button id="nav-toggle" ref={navButton} onClick={toggleNavHidden}>
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>

      <div id="nav-bg" />
      <nav id="nav-menu" onClick={hideNavHidden}>
        <ul>
          <li>
            <a href="#top" onClick={hideNavHidden}>
              <span>Top</span>
            </a>
          </li>
          <li>
            <a href="#news" onClick={hideNavHidden}>
              <span>News</span>
              <span className="hint">店舗のお知らせ</span>
            </a>
          </li>
          <li>
            <a href="#concept" onClick={hideNavHidden}>
              <span>Concept</span>
              <span className="hint">コンセプト</span>
            </a>
          </li>
          <li>
            <a href="#catalog" onClick={hideNavHidden}>
              <span>Catalog</span>
              <span className="hint">カタログ</span>
            </a>
          </li>
          <li>
            <a href="#menu" onClick={hideNavHidden}>
              <span>Menu</span>
              <span className="hint">メニュー</span>
            </a>
          </li>
          <li>
            <a href="#information" onClick={hideNavHidden}>
              <span>Information</span>
              <span className="hint">店舗情報</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
