import React from 'react';

type Props = {
  title: string;
  content: string;
  time: string;
  onClose: () => void;
};

const NewsDetail: React.FC<Props> = ({ title, content, time, onClose }) => {
  return (
    <>
      <div className="overlay-blur" onClick={onClose} />
      <div className="viewer">
        <div className="title">{title}</div>
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        <time>{time}</time>
        <div className="close" onClick={onClose}>
          閉じる
        </div>
      </div>
    </>
  );
};

export default NewsDetail;
