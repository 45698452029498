import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import moment from 'moment';

import Layout from '~/components/layout';
import Header from '~/components/header';
import Seo from '~/components/seo';
import NewsDetail from '~/components/newsDetail';

import { IndexPageQuery } from '~/graphql-types';

const IndexPage: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  const config = data.dataYaml ?? {};
  const menuCategories = React.useMemo(() => data.allMenuYaml.edges.map((e: any) => e.node), [data.allMenuYaml]);
  const news = React.useMemo(
    () =>
      data.allMarkdownRemark.edges.map((e: any) => ({
        ...e.node.frontmatter,
        time: moment(e.node.frontmatter.time).format('YYYY.M.D'),
      })),
    [data.allMarkdownRemark]
  );

  const brandLogo = React.useMemo(
    () => (
      <>
        <span>Hermy</span>
        <br />
        <span>NAIL SALON</span>
      </>
    ),
    []
  );

  const [selectedNews, selectNews] = React.useState();

  const showNews = React.useCallback(news => {
    return () => {
      selectNews(news);
    };
  }, []);

  const [isDisplayHeader, setIsDisplayHeader] = React.useState(false);
  const newsRef = React.useRef<HTMLElement>(null);
  const isRunning = React.useRef(false);

  const isScrollToggle = React.useCallback(() => {
    if (isRunning.current) return;
    isRunning.current = true;

    if (newsRef.current == null) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const newsOffsetTop = newsRef.current.offsetTop;

    requestAnimationFrame(() => {
      if (scrollTop > newsOffsetTop) {
        setIsDisplayHeader(true);
      } else {
        setIsDisplayHeader(false);
      }
      isRunning.current = false;
    });
  }, [newsRef.current]);

  React.useEffect(() => {
    document.addEventListener('scroll', isScrollToggle, { passive: true });
    return () => {
      document.removeEventListener('scroll', isScrollToggle);
    };
  }, []);

  return (
    <Layout>
      <Seo />
      {isDisplayHeader && (
        <aside>
          <Header brandLogo={brandLogo} tel={config.tel || ''} line={config.line?.url || ''} />
        </aside>
      )}
      <h1 className="catchcopy">上大岡の爪に優しいネイルサロン</h1>
      <section id="top">
        <div className="left">
          <h1 className="brand logo">{brandLogo}</h1>
          <div className="contact">
            <div className="call">
              <i className="icon-phone"></i>
              <address>
                <a href={`tel: ${config.tel}`}>{config.tel}</a>
              </address>
            </div>
            <div className="hint">
              お電話、LINE@より
              <br />
              ご予約承っております
            </div>

            <div className="social">
              <div
                className="fb-like"
                data-href="https://hermynail.salon"
                data-layout="box_count"
                data-action="like"
                data-size="small"
                data-show-faces="true"
                data-share="true"
              ></div>
              <button className="line-follow-me" onClick={() => (location.href = config.line?.url || '')}>
                <div>
                  <i className="icon-line"></i>
                  <span>友だち追加</span>
                </div>
              </button>
              <button
                className="instagram-follow-me"
                onClick={() => open(`https://www.instagram.com/${config.instagram?.username}?ref=badge`, '_blank')}
              >
                <div>
                  <i className="icon-instagram"></i>
                  <span>フォローする</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <nav className="right">
          <ul>
            <li>
              <a href="#concept">
                Concept<span className="hint">コンセプト</span>
              </a>
            </li>
            <li>
              <a href="#menu">
                Menu<span className="hint">メニュー</span>
              </a>
            </li>
            <li>
              <a href="#information">
                Information<span className="hint">店舗情報</span>
              </a>
            </li>
          </ul>
        </nav>
      </section>

      <section id="news" className="content" ref={newsRef}>
        <div className="shadow"></div>
        <h1>
          <span>News</span>
          <span className="hint">店舗のお知らせ</span>
        </h1>
        <ul>
          {news.map(
            (n: any, i: number) =>
              n.title && (
                <li key={i}>
                  <time>{n.time}</time>
                  <h3 onClick={showNews(n)}>{n.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: n.content }} />
                </li>
              )
          )}
        </ul>
        {selectedNews && <NewsDetail {...selectedNews} onClose={() => selectNews(undefined)} />}
      </section>

      <section id="concept" className="content">
        <h1>
          <span>Concept</span>
          <span className="hint">コンセプト</span>
        </h1>
        <div className="text">
          <h2>
            指先から大人可愛く
            <br />
            爪への優しさにこだわった
            <br />
            高品質なネイルサロン
          </h2>
          <p>
            当サロンでは、いつまでもネイルを楽しんでいただくために、自爪を削らないジェル
            <a href="#paragel">[パラジェル]</a>、自爪に優しいアセトンを使わないオフ<a href="#fill-in">[フィルイン]</a>
            をお薦めさせていただいております。
          </p>
          <p>
            長持ちのための技術と爪に優しい上質なジェルを取扱っていることはもちろんのこと、施術中はリラックスしていただけるように癒やしの空間づくりにもこだわっているため、丁寧なケア、おしゃれなデザインを最新技術にてお楽しみいただくと共に、日々の疲れもリフレッシュしていただけます。
          </p>
          <p>
            Hermy
            nailは、お客様１人１人のご希望やお悩みに向き合い、寄り添い、そしてご一緒に最高のネイルを創っていく、そんな「大人可愛いネイルサロン」です。
          </p>
          <div className="owner-sign">
            Hermy nail オーナー
            <br />
            ネイリスト 二宮 優帆
          </div>

          <h2 id="paragel">パラジェル登録サロン</h2>
          <p>
            従来のジェルと違い、パラジェルは爪を削らない（サンディング不要な）ジェルのため、爪へのダメージを最小限に抑えることができます。
            また、爪に染み込ませずに密着させるのでオフも簡単で爪に優しく、強度と耐久性も高く変色も少ないです。爪との相性も良いため、良い状態で長い期間お楽しみいただくことができます。
          </p>
          <p>
            国産ブランドであることや、UVライトを使用せずLEDライトを使用しますので、紫外線アレルギーの方でも安心してご利用いただくことができるため、Hermy
            nail ではパラジェルを採用しております。
          </p>

          <h2 id="fill-in">フィルイン導入</h2>
          <p>
            従来の刺激の強い溶剤(アセトン)を使用したオフではなく、Hermy nail
            ではベースジェルを一層残す、マシーンによるフィルインをご提供しております。パラジェルと併用することで、爪への負担を最小限に抑え健康な爪を育てながら、いつまでもネイルをお楽しみいただくことができます。
          </p>

          <h2 id="water-care">全メニューにウォーターケア付き</h2>
          <p>
            お湯で角質を柔らかくしてから角質を処理することで、仕上がりが良くなりやすく、爪とジェルの密着が良くなるためジェルが浮き難く、長い期間お楽しみいただけます。
          </p>
          <p>サロンによっては別料金(¥3,000前後)のウォータケアが、Hermy nail では全メニューの料金内に含まれています。</p>
        </div>
      </section>

      <section id="menu" className="content">
        <h1>
          <span>Menu</span>
          <span className="hint">メニュー</span>
        </h1>
        <span className="hint">金額は税込表示となっております</span>

        <div className="content">
          {menuCategories.map((menu: any, i: number) => (
            <section id={`ground-menu-${i + 1}`} key={i}>
              <h3>
                {menu.category_name.en}
                <span className="hint">{menu.category_name.ja}</span>
              </h3>
              <ul>
                {menu.details.map((detail: any, i: number) => (
                  <li key={i}>
                    <h4>{detail.name}</h4>
                    <div className="price">{`¥${detail.price?.toLocaleString()}`}</div>
                    {detail.desc && <div className="desc">{detail.desc}</div>}
                    {detail.links && (
                      <div className="links">
                        {detail.links.map((link: any, i: number) => (
                          <a href={link.url} target={link.target} key={i}>
                            {link.text}
                          </a>
                        ))}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>
      </section>

      <section id="information" className="content">
        <h1>
          <span>Information</span>
          <span className="hint">店舗情報</span>
        </h1>
        <dl>
          {config.opentime && (
            <>
              <dt>営業時間</dt>
              <dd className="open">
                <ul>
                  {config.opentime.weekday && (
                    <li>
                      {config.opentime.weekday.open} - {config.opentime.weekday.close}
                      <span className="hint">{config.opentime.weekday.hint}</span>
                    </li>
                  )}

                  {config.opentime.weekend && (
                    <li>
                      {config.opentime.weekend.open} - {config.opentime.weekend.close}
                      <span className="hint">{config.opentime.weekend.hint}</span>
                    </li>
                  )}
                </ul>
              </dd>
            </>
          )}
          <dt>定休日</dt>
          <dd className="holiday">{config.holiday}</dd>
          <dt>電話番号</dt>
          <dd className="tel">
            <address>
              <a href={`tel: ${config.tel?.replace('-', '')}`}>{config.tel}</a>
            </address>
          </dd>
          <dt>駐車場</dt>
          <dd>{config.parking}</dd>
          <dt>アクセス</dt>
          <dd className="access">
            <div>{config.access}</div>
            <address>{config.address}</address>
          </dd>
        </dl>

        {config.coordinate && (
          <LoadScript googleMapsApiKey="AIzaSyAp6wwfB25kKrl9K4mnRHnjwvFnf60_Yh8">
            <GoogleMap
              mapContainerStyle={{
                position: 'relative',
                width: '100%',
                height: '320px',
                background: '#ccc',
                marginTop: 8,
              }}
              center={{ lat: config.coordinate.lat ?? 0, lng: config.coordinate.lng ?? 0 }}
              zoom={15}
            >
              <Marker position={{ lat: config.coordinate.lat ?? 0, lng: config.coordinate.lng ?? 0 }} />
            </GoogleMap>
          </LoadScript>
        )}
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexPage {
    dataYaml {
      name
      access
      address
      coordinate {
        lat
        lng
      }
      facebook {
        page_id
      }
      holiday
      instagram {
        username
        user_id
        client_id
        access_token
      }
      line {
        url
      }
      opentime {
        weekday {
          open
          close
          hint
        }
        weekend {
          open
          close
          hint
        }
      }
      parking
      tel
    }
    allMenuYaml {
      edges {
        node {
          category_name {
            en
            ja
          }
          details {
            name
            price
            desc
            links {
              url
              text
            }
          }
        }
      }
    }

    allMarkdownRemark(
      sort: { fields: frontmatter___time, order: DESC }
      filter: { frontmatter: { collectionType: { eq: "news" } } }
    ) {
      edges {
        node {
          frontmatter {
            time
            title
            content
          }
        }
      }
    }
  }
`;

export default IndexPage;
